import React from "react";
import BookingForm2 from "../components/Booking/BookingFormHooks";

const Booking = () => {

    return (
        <div>
            <BookingForm2 />
        </div>
    )
}

export default Booking