import React from "react";
import { FooterBox, FooterContainer, FooterUl, Ul, H3, links } from "./FooterStyles";
import { NavLink } from "react-router-dom";
import Contact from "./Contact";
import SocialMedia from "./SocialMedia/SocialMedia";

const Footer = () => {

    return (
        <FooterBox>
            <FooterContainer>
                Copyright &copy; 2022 QC Boat Detailing - All Rights Reserved
                <div>
                    <FooterUl>
                        {links.map((link, index) =>
                            <NavLink key={index} to={link.path}>
                                <Ul>{link.name}</Ul>
                            </NavLink>
                        )}
                    </FooterUl>
                </div>
                {/* <div>
                    <FooterUl>
                        <H3>Legal</H3>
                        {legal_links.map((link, index) =>
                            <NavLink key={index} to={link.path}>
                                <Ul>{link.name}</Ul>
                            </NavLink>
                        )}
                    </FooterUl>
                </div> */}
                <div>
                    <Contact />
                </div>
                <div>
                    <SocialMedia />
                </div>
            </FooterContainer>
        </FooterBox>
    )
}

export default Footer