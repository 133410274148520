import React from "react";
import { AiOutlineInstagram } from 'react-icons/ai';
import { FiFacebook } from 'react-icons/fi';
import { SocialBox, SocialContainer, SocialLink } from "./SocialStyles";
import { H3 } from "../FooterStyles";
const SocialMedia = () => {

    return (
        <SocialBox>
            <H3>Follow Us</H3>
            <SocialContainer>
                <SocialLink href="https://www.facebook.com/profile.php?id=100083724134807" target='_blank'>
                    <span><FiFacebook size={32} /></span>
                </SocialLink>
                <SocialLink href="https://www.instagram.com/qcboatdetailing/" target='_blank'>
                    <span><AiOutlineInstagram size={36} /></span>
                </SocialLink>
            </SocialContainer>
        </SocialBox>
    )
}

export default SocialMedia