import React from "react";
import { SectionBody, SectionBox, H1, List, BulletText } from "./PageStyles";

const Services = () => {

    return (
        <SectionBody aria-labelledby="services">
            <SectionBox>
                <H1>Services Provided</H1>
                <List>
                    <li><BulletText >Wash</BulletText></li>
                    <li><BulletText >Non-skid Cleaning</BulletText></li>
                    <li><BulletText >Compartment Cleaning</BulletText></li>
                    <li><BulletText >Mold &amp; Mildew Removal</BulletText></li>
                    <li><BulletText >Bright Work Waxing</BulletText></li>
                    <li><BulletText >Hull Cleaning</BulletText></li>
                    <li><BulletText >Engine Cleaning</BulletText></li>
                    <li><BulletText >Rub Rail Down Wax</BulletText></li>
                    <li><BulletText >Top Side Wax</BulletText></li>
                </List>
            </SectionBox>
        </SectionBody>
    )
}

export default Services