import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppDiv, Main } from './AppSyles';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer'
import Landing from './pages/Landing';
import About from './pages/About';
import Booking from './pages/Booking';
import Services from './pages/Services';
import Sitemap from './pages/Sitemap';
import TermsConditions from './pages/TermsConditions';
import Products from './pages/Products';
import { BackgroundVideo } from './components/NavBar/NavBarStyles';
import backgroundVideo from "./assets/Yacht.mp4";
import OnePage from './pages/OnePage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <AppDiv>
          <NavBar className='nav' />
          <Main>
            <BackgroundVideo
              autoPlay
              loop
              muted
              src={backgroundVideo}
              type='video/mp4'
              playsInline
              controls={false}
            />
            {/* <OnePage /> */}
            <Routes>
              <Route path='/' element={<Landing />} />
              <Route path='/about' element={<About />} />
              <Route path='/booking' element={<Booking />} />
              <Route path='/services' element={<Services />} />
              <Route path='/sitmap' element={<Sitemap />} />
              <Route path='/terms&amp;conditions' element={<TermsConditions />} />
              <Route path='/products' element={<Products />} />
            </Routes>
          </Main>
          <Footer className='footer' />
        </AppDiv>
      </>
    </ThemeProvider>
  );
}

export default App;
