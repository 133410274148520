import styled from "styled-components";

export const BookingBox = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    background-color: navy;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    padding: 1rem 4rem 3rem 2rem;
    background: rgba(5, 105, 107, 0.5);
    border: 5px solid transparent;
    border-image-source: url('rope.jpeg');
    border-image-slice: 55;
    border-image-width: .5rem .5rem .5rem .5rem;
    border-image-outset: 0;
    border-image-repeat: round;
`
export const LabelBox = styled.label`
    text-align: left;
    font-size: 20px;
`
export const BookingUl = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 30vw;
    margin: 0;

    @media screen and (max-width: 500px) {
        width: auto;
    }
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: .5rem 0;

    input {
        width: 100%;
    }

    span {
        color: red;
    }
`