import styled from 'styled-components';

export const AppDiv = styled.div`
    height: 100%;
    width: 100%;
`

export const Main = styled.main`
    background-color: rgba(5, 105, 107, 0.1);
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background-attachment: fixed;
    position: relative;
    
    @media (max-width: 1000px) {
        padding: 4.3rem 1.8rem;
    }
`