import React from "react";
import { NavLink } from "react-router-dom";
import { NavBox, NavBarContainer, NavUl, Ul, links, Logo } from "./NavBarStyles";
import logo from "../../assets/qcLogo.png";

const NavBar = () => {

    return (
        <NavBox>
            <NavBarContainer>
                <Logo src={logo} alt="boat in sun" />
                <NavUl>
                    {links.map((link, index) => (
                        <NavLink key={index} to={link.path}>
                            <Ul className="nav">{link.name}</Ul>
                        </NavLink>
                    ))}
                </NavUl>
            </NavBarContainer>
        </NavBox>
    )
}

export default NavBar