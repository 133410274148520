import React from "react";

const TermsConditions = () => {

    return (
        <div>
            Terms &amp; Conditions Page
        </div>
    )
}

export default TermsConditions