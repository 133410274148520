import React from "react";
import { ContactUl, H3 } from "./FooterStyles";

const Contact = () => {

    return (
        <ContactUl>
            <H3>Contact Info</H3>
            <address>(727) 687-0196</address>
            <address>QCBoatDetailing@gmail.com</address>
        </ContactUl>
    )
}

export default Contact