import styled from "styled-components";

export const SectionBody = styled.section`
    display: flex;
    flex-direction: column;
`

export const SectionBox = styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    margin: 3rem 15rem 3rem 15rem;
    &:last-child {
        margin-bottom: 3rem;
    }
    padding: 1rem 2rem;
    background: rgba(5, 105, 107, 0.5);
    border: 10px solid transparent;
    border-image-source: url('rope.jpeg');
    border-image-slice: 55;
    border-image-width: .5rem .5rem .5rem .5rem;
    border-image-outset: 0;
    border-image-repeat: round;

    @media screen and (max-width: 990px) {
        padding: 0;
    }
    @media screen and (max-width: 850px) {
        margin: 3rem 0 3rem 0;
        padding: 1rem 1rem;
    }
`

export const H1 = styled.h1`
    color: white;
    padding: .5rem 1rem;
    border-radius: 5px;
    width: auto;
    margin: 1rem 0;
`

export const H2 = styled.h2`
    color: whitesmoke;    
`

export const H3 = styled.h3`
    color: white;
    padding: .5rem 1rem;
    border-radius: 5px;
    width: auto;
    margin: 1rem 0;
`

export const List = styled.ul`
    list-style: square;
    list-style-position: inside;
    color: goldenrod;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: .5rem 1rem;
    width: auto;
    margin: 1rem 0;
`

export const BulletText = styled.span`
    color: white;
`

export const EstimateButton = styled.a`
    background: orangered;
    padding: 10px 15px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
    display: flex;

    h3 {
        margin: 0;
    }
`

export const Para = styled.p`
    color: white;
    padding: 0 5rem 0 5rem;
    border-radius: 5px;
    width: auto;

    @media screen and (max-width: 850px) {
        padding: 0 0 0 0;
    }
`