import styled from "styled-components";

export const SocialBox = styled.div`
    grid-area: social;
    justify-content: left;

    @media (max-width: 1000px) {
        padding: 70px 30px;
    }
`

export const SocialContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 100vw;
`

export const SocialLink = styled.a`
    color: whitesmoke;
    font-size: 18px;
    text-decoration: none;

    span {
        &:hover {
            color: red;
            transition: 200ms ease-in;
            cursor: pointer;
            font-size: 22px;
        }
    }
`