import React from 'react';
import { SectionBody, SectionBox, H1, List, BulletText } from './PageStyles';

const Products = () => {
    return (
        <SectionBody>
            <SectionBox>
                <H1>
                    Products
                </H1>
                <List>
                    <li><BulletText>Ardex Super 600 Compound Alternative</BulletText></li>
                    <li><BulletText>Menzerna 400 Compound / Polish Alternative</BulletText></li>
                    <li><BulletText>Jescar Sealant</BulletText></li>
                    <li><BulletText>McKee’s 37 Hydro Blue Alternative</BulletText></li>
                    <li><BulletText>Orpine Wash &amp; Wax Soap</BulletText></li>
                    <li><BulletText>Interface pad</BulletText></li>
                    <li><BulletText>Foam Cannon</BulletText></li>
                    <li><BulletText>Woody Wax</BulletText></li>
                    <li><BulletText>Never Dull</BulletText></li>
                    <li><BulletText>Zephyr 40</BulletText></li>
                    <li><BulletText>Acetone</BulletText></li>
                    <li><BulletText>Purple Power</BulletText></li>
                    <li><BulletText>Rust Aid</BulletText></li>
                    <li><BulletText>IGL eco shine</BulletText></li>
                </List>
            </SectionBox>
        </SectionBody>
    )
}

export default Products