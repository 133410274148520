import styled from 'styled-components';

export const NavBox = styled.nav`
    grid-area: nav;
    background-color: rgba(5, 105, 107, 0.75);
    top: 0;
    left: 0;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
`

export const NavBarContainer = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: center;
        img{
            margin: 2rem 0 0 0;
            width: 40%;
        }
        ul {
            padding: 0 0.75rem;
        }
    }
`

export const NavUl = styled.ul`
    display: flex;
    flex-direction: row;
    padding: 1rem 0;

    a {
        text-decoration: none;

        &:hover{
            color: whitesmoke;
            transition: 200ms ease-in;
        }
    }

    a:link {
        color: whitesmoke;
    }
`

export const Ul = styled.ul`
    color: white;
    font-family: 'Pathway Gothic One', sans-serif;
    
    &:hover {
        color: skyblue;
        transition: 200ms ease-in;
    }

    &:last-child {
        padding-right: 0;
    }
`

export const links = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    { name: 'Services', path: '/services' },
    { name: 'Booking', path: '/booking' }
]

export const Logo = styled.img`
    box-sizing: border-box;
    display: inline;
    width: 12%;
    height: 12%;
    margin: 2rem;
`

export const BackgroundVideo = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
`