import React from "react";

const Sitemap = () => {

    return (
        <div>
            Sitemap
        </div>
    )
}

export default Sitemap