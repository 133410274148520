import React, { useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { BookingBox, BookingUl, LabelBox, InputContainer } from './BookingStyles';


const BookingForm2 = () => {
    // get functions to build form with useForm() hook
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState,
        formState: { errors, isSubmitSuccessful } } = useForm({
            defaultValues: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                street1: '',
                street2: '',
                city: '',
                zipCode: '',
                inWater: '',
                inMarina: '',
                bayNumber: ''
            }
        });

    const form = useRef();

    const serviceId = process.env.REACT_APP_MY_SERVICE_ID;
    const templateId = process.env.REACT_APP_MY_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_MY_PUBLIC_KEY

    const [formData, setFormData] = useState({});

    const sendEmail = (formData, e) => {
        setFormData(formData);
        e.preventDefault();
        emailjs.sendForm(`${serviceId}`, `${templateId}`, form.current, `${publicKey}`)
            .then(() => {
                alert(`Thank you, ${formData.firstName}! We will be in touch soon.`)
            }, (error) => {
                alert(error + `Sorry, ${formData.firstName}. You're form failed to send.`);
            });
    };

    const onSubmit = sendEmail;
    const watchInMarina = watch("inMarina");

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                street1: '',
                street2: '',
                city: '',
                zipCode: '',
                inWater: '',
                inMarina: '',
                bayNumber: ''
            });
        }
    }, [formState, formData, reset]);

    return (
        <BookingBox>
            <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                <BookingUl>
                    <LabelBox htmlFor="customerContact">Your Contact Information</LabelBox>
                    <InputContainer>
                        <label>First Name*</label>
                        <input
                            type="text"
                            name='firstName'
                            placeholder="First name"
                            aria-invalid={errors.name ? "true" : "false"}
                            {...register("firstName", { required: true, maxLength: 80 })}
                        />
                        {errors.firstName && errors.firstName?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <label>Last Name*</label>
                        <input
                            type="text"
                            name='lastName'
                            placeholder="Last name"
                            aria-invalid={errors.name ? "true" : "false"}
                            {...register("lastName", { required: true, maxLength: 80 })}
                        />
                        {errors.lastName && errors.lastName?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="tel"
                            name='phoneNumber'
                            placeholder="Phone Number"
                            aria-invalid={errors.phoneNumber ? "true" : "false"}
                            {...register("phoneNumber", { required: true, min: 10 })}
                        />
                        {errors.phoneNumber && errors.phoneNumber?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            name='email'
                            placeholder="Email"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                        />
                        {errors.email && errors.email?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                    </InputContainer>
                    <LabelBox htmlFor="address">Boat Address</LabelBox>
                    <InputContainer>
                        <label htmlFor="street1">Street 1</label>
                        <input
                            type="text"
                            name='street1'
                            placeholder="Street 1"
                            aria-invalid={errors.street1 ? "true" : "false"}
                            {...register("street1", { required: true })}
                        />
                        {errors.street1 && errors.street1?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <label htmlFor="street2">Street 2</label>
                        <input
                            type="text"
                            name='street2'
                            placeholder="Street 2"
                            {...register("street2", {})}
                        />
                    </InputContainer>
                    <InputContainer>
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            name='city'
                            placeholder="City"
                            aria-invalid={errors.city ? "true" : "false"}
                            {...register("city", { required: true })}
                        />
                        {errors.city && errors.city?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <label htmlFor="zipCode">Zip Code</label>
                        <input
                            type="text"
                            name='zipCode'
                            placeholder="Zip Code"
                            aria-invalid={errors.zipCode ? "true" : "false"}
                            {...register("zipCode", { required: true, maxLength: 5 })}
                        />
                        {errors.zipCode && errors.zipCode?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                    </InputContainer>
                    <LabelBox htmlFor="inWater">Is Boat In or Out of Water?</LabelBox>
                    <InputContainer>
                        <select
                            name='inWater'
                            aria-invalid={errors.inWater ? 'true' : 'false'}
                            {...register("inWater", { required: true })}
                        >
                            <option
                                // key={null}
                                value=''></option>
                            <option
                                key='In'
                                value="In">In</option>
                            <option
                                key='Out'
                                value="Out">Out</option>

                        </select>
                        {errors.inWater && errors.inWater?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                    </InputContainer>
                    <LabelBox htmlFor='inMarina'>Is Boat In a Marina?</LabelBox>
                    <InputContainer>
                        <select
                            name='inMarina'
                            aria-invalid={errors.inMarina ? "true" : "false"}
                            {...register("inMarina", { required: true })}
                        >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        {errors.inMarina && errors.inMarina?.type === "required" && (
                            <span role="alert">This Field is Required</span>
                        )}
                        {watchInMarina === "Yes" &&
                            <input
                                type="text"
                                name='bayNumber'
                                placeholder="Bay Number"
                                aria-invalid={errors.bayNumber ? "true" : "false"}
                                {...register("bayNumber", { required: true })}
                            />
                        }
                    </InputContainer>
                </BookingUl>
                <input type="submit" value="Send" />
            </form>
        </BookingBox>
    );
}

export default BookingForm2