import styled from "styled-components";

export const FooterBox = styled.footer`
    grid-area: footer;
    background-color: #05696B;
    font-size: calc(2rem + 2vmin);
    color: whitesmoke;
    padding: 1rem;
    bottom: 0;
    left: 0;
    width: 100%;

    @media (max-width: 1000px) {
        padding:70 px 30px;
    }
`

export const FooterContainer = styled.div`
    max-width: 100%;
    color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 25%;

    @media (max-width: 850px) {
        flex-direction: column-reverse;
        ul {
            padding: 1rem 1rem;
            width: 50%;
        }
    }
`
export const FooterUl = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: left;
    color: whitesmoke;
    font-size: 12px;
    
    a {
        text-decoration: none;

        &:hover{
            color: whitesmoke;
            font-size: 18px;
            transition: 200ms ease-in;
            text-decoration: underline;
        }
    }

    a:link {
        color: whitesmoke;
    }
`

export const Ul = styled.li`
    grid-area: links;
    color: whitesmoke;
    list-style-type: none;
    &:hover {
        color: whitesmoke;
        transition: 200ms ease-in;
    }
`

export const ContactUl = styled.ul`
    color: whitesmoke;
    font-size: 12px;
    grid-area: contact;
`
export const H3 = styled.h3`
    font-size: 14px;
    font-weight: bold;
    line-height: 0;
`

export const links = [
    { name: 'About', path: '/about' },
    { name: 'Services', path: '/services' },
    // { name: 'Sitemap', path: '/sitemap' },
    { name: 'Products', path: '/products' }
]

// export const legal_links = [
//     { name: 'Terms & Conditions', path: '/terms&conditions' },
//     { name: 'Privacy Statement', path: '/privacy_statement' },
// ]