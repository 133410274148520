import React from "react";
import { SectionBody, SectionBox, List, BulletText, EstimateButton, H1, H3 } from "./PageStyles";
const Landing = () => {

    return (
        <SectionBody aria-labelledby="homepage">
            <SectionBox aria-labelledby="s1-h1">
                <H1>Offering a top-notch detailing service for your entire boat. <br />The difference is night and day!</H1>
            </SectionBox>
            <SectionBox aria-labelledby="s2-h2">
                <H3>Providing you a top-quality clean that will cover any needs: <br />from removing unwanted rust or fish stains; <br />to the safe removal of barnacles; <br />to reversing paint fade due to oxidation from the sun.</H3>
            </SectionBox>
            <SectionBox>
                <h3>
                    <EstimateButton href="/booking" aria-labelledby="estimate">
                        Request Your Estimate Today
                    </EstimateButton>
                </h3>
            </SectionBox>
            <SectionBox>
                <List>
                    <h3>Benefits of a Clean Boat</h3>
                    <li><BulletText>Removes Dirt, Mildew, Barnacles, Algae &amp; Sludge</ BulletText></li>
                    <li><BulletText>Improves Fuel Economy</ BulletText></li>
                    <li><BulletText>Prevents Potential Hull Damage</ BulletText></li>
                    <li><BulletText>Improves Appearance</ BulletText></li>
                    <li><BulletText>Improves Overall Performance</ BulletText></li>
                    <li><BulletText>Saves Money</ BulletText></li>
                    <li><BulletText>Reduces Haul Outs</ BulletText></li>
                    <EstimateButton href="/booking">
                        <h3>Get a Maintenance Schedule!</h3>
                    </EstimateButton>

                </List>
            </SectionBox>
        </SectionBody>
    )
}

export default Landing