import React from "react";
import { SectionBody, SectionBox, H2, Para } from "./PageStyles";

const About = () => {

    return (
        <SectionBody aria-labelledby="About us">
            <SectionBox>
                <H2>Who We Are</H2>
                <Para>
                    Here at Quality Cleaners, we work hard to provide a superior service
                    to the good people of the Tampa Bay area. We take pride in our work which
                    translates into excellent results for our customers, giving you a night &amp; day difference to leave your boat looking as good as the day you bought it.
                </Para>
            </SectionBox>
            <SectionBox>
                <H2>What We Do</H2>
                <Para>
                    Our work will leave your boat spotless from bow to stern and inside every compartment to under every seat as well as protected from the harsh elements we encounter on a daily basis here in Florida for 6-8 months!
                </Para>
            </SectionBox>
        </SectionBody >
    )
}

export default About